.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contact-image {
  transform: rotate(270deg);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  padding: 20px;
}

.wrapper-inside {
  padding: 8px;
}

.brand {
  text-decoration: initial;
  color: initial;
}

.text-shadow {
  background-color: rgba(21,76,121,0.5);
}

.jumbo-text{
  color: rgb(238,238,228);
}

.tall {
  height: 600px;
}

@media screen and (max-width: 600px) {
  .card-custom {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.card-custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  float: left;
  width: 33%;
  padding: 0 10px;
  min-width:300px;
}

.card-custom:after {
  content: "";
  display: table;
  clear: both;
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000; 
  font-weight: bold;
}

@media screen and (min-width: 1280px){
  .home-banner {
    min-height: 260px;
  }
}
/* src/components/HomeBanner.css */
.home-banner {
  background-color: #f8f9fa; /* Light grey background */
  padding: 20px;
  border-radius: 10px;
}

.home-banner h1 {
  font-family: 'Arial', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.home-banner p {
  font-family: 'Georgia', serif;
  margin-bottom: 10px;
}

.home-banner img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}